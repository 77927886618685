/* eslint-disable react/jsx-pascal-case */
import Placeholder from 'react-bootstrap/Placeholder';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Event2025_02_08 from './2025/E2025_02_08_JoSportolo';
import Event2025_02_21 from './2025/E2025_02_21_Tibi_Edzoi';
import Event2025_03_01 from './2025/E2025_03_01_Adam_2kyu';
import Event2025_03_03 from './2025/E2025_03_03_Vizsga';
import Event2025_03_22 from './2025/E2025_03_22_Kapuvar';
import Event2025_03_30 from './2025/E2025_03_30_Varpalota';
function PageEvents(props) {
    return (
        <div className='eventpage'>
            <Row>
                <h2>ESEMÉNYEK</h2>
            </Row>
            <Row className='border-all'>
                <Event2025_03_30></Event2025_03_30>
            </Row>
            <Row className='border-all'>
                <Event2025_03_22></Event2025_03_22>
            </Row>
            <Row className='border-all'>
                <Event2025_03_03></Event2025_03_03>
            </Row>
            <Row className='border-all'>
                <Event2025_03_01></Event2025_03_01>
            </Row>
            <Row className='border-all'>
                <Event2025_02_21></Event2025_02_21>
            </Row>
            <Row className='border-all'>
                <Event2025_02_08></Event2025_02_08>
            </Row>
            <Row className='border-blr'>
            </Row>
            <Row className='border-blr'>
            </Row>
        </div>
    )
}

export default PageEvents;