import { useState, useEffect } from "react";
import Grid1 from "./grid1";
import Grid2 from "./grid2";
import Grid3 from "./grid3";
import './pagestyle.scss'

function PageMain(props) {
    const [pageState, setPageState] = useState('GRID1')
    const [operationState, setOperationState] = useState('NONE')
    const [currDataRow, setCurrDataRow] = useState();
    const [grid1RowData, setGrid1RowData] = useState(null);
    const [grid2RowData, setGrid2RowData] = useState(null);
    const [grid3RowData, setGrid3RowData] = useState(null);
    var biRef = {}

    useEffect(() => {
        // biRef.SetFormFields_f(currDataRow);
        biRef.SetParent_grid2(currDataRow);
    }, [currDataRow]);

    return (
        <div>
            <Grid1
                setPageState={setPageState}
                pageState={pageState}
                operationState={operationState}
                setOperationState={setOperationState}
                token={props.token}
                userName={props.userName}
                sportAbilitiesComboData={props.sportAbilitiesComboData}
                setCurrDataRow={setCurrDataRow}
                grid1RowData={grid1RowData}
                setGrid1RowData={setGrid1RowData}
                biRef={biRef}
            >
            </Grid1>
            <Grid2
                setPageState={setPageState}
                pageState={pageState}
                operationState={operationState}
                setOperationState={setOperationState}
                userName={props.userName}
                token={props.token}
                parentGridId="GRID1"
                biRef={biRef}
                grid1RowData={grid1RowData}
                grid2RowData={grid2RowData}
                setGrid2RowData={setGrid2RowData}
                currDataRow={currDataRow}
            >
            </Grid2>
            <Grid3
                setPageState={setPageState}
                pageState={pageState}
                operationState={operationState}
                setOperationState={setOperationState}
                userName={props.userName}
                token={props.token}
                parentGridId="GRID2"
                biRef={biRef}
                grid1RowData={grid1RowData}
                grid2RowData={grid2RowData}
                grid3RowData={grid3RowData}
                setGrid2RowData={setGrid2RowData}
                currDataRow={currDataRow}
            >
            </Grid3>
        </div>
    )
}

export default PageMain;