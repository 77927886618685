/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useMemo, useEffect, useCallback } from "react";
import openRenderer from "../../components/renderers/openRenderer";
import saveRenderer2 from "../../components/renderers/saveRenderer2";
import { validators } from "../../components/validators/validators";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ShowData from "../../components/datahandling/showdata";
import SaveData from "../../components/datahandling/savedata";
import Ajv from 'ajv';
import { ajvSchemaExercise, createNewRowDataExercise } from "./gridDef";
import DelRow2 from "../../components/datahandling/deletedata";
const myAjv = new Ajv()

function GridExercise(props) {
    const gridRef = useRef();
    const myCollection = 'vExercise';
    const [editingRowId, setEditingRowId] = useState(null); // A grid sorban szerkesztéskor a save gomb megjelenítéséhez kell.
    const [rowIndex, setRowIndex] = useState();

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
    }), []);

    const columnDefs = useMemo(() => [
        { field: 'ID', hide: true },
        {
            field: 'ExerciseName',
            headerName: 'Gyakorlat',
            cellEditor: 'agTextCellEditor',
            editable: true,
            filter: true,
            cellStyle: params => {
                return validators.validate('required', params.value, props.language)
                    ? { backgroundColor: '#6e100a' }
                    : null;
            },
        },
        {
            field: 'ExerciseDescription',
            headerName: 'Leírás',
            cellEditor: 'agTextCellEditor',
            editable: true,
            filter: true,
        },
        {
            field: 'btnopenform1',
            width: 70,
            headerName: '...',
            cellRenderer: openRenderer,
            cellRendererParams: { form: 'CHILD1', setPageState: props.setFormState },
        },
        {
            field: 'SportAbilityName',
            headerName: 'Képesség',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: { values: props.sportAbilitiesComboData },
            editable: true,
            filter: true,
            cellStyle: params => {
                return validators.validate('required', params.value, props.language)
                    ? { backgroundColor: '#6e100a' }
                    : null;
            },
        },
        {
            field: 'ResToExString',
            headerName: 'Várt eredmények',
            cellEditor: 'agTextCellEditor',
            editable: false,
            filter: true,
        },
        {
            field: 'btnopengrid2',
            width: 70,
            headerName: '...',
            cellRenderer: openRenderer,
            cellRendererParams: { form: 'GRID2', setPageState: props.setFormState },
        },
        {
            field: 'btsave',
            width: 50,
            headerName: '💾',
            cellRenderer: saveRenderer2,
            valueGetter: () => "", // 🔄 Üres érték, de a renderer meghívódik
        },
    ], [editingRowId]);

    // 🔹 Adatok betöltése
    useEffect(() => {
        ShowMyData();
    }, []);

    useEffect(() => {
        stateChangeHandling();
    }, [props.formState])

    async function ShowMyData() {
        await ShowData({
            token: props.token,
            setRowData: props.setExerciseRowData,
            language: 'HU',
            userName: props.userName,
            collection: myCollection,
            where: '',
        });
    }

    // Ha egy adat sorra kattintok, akkor frissíti a RresToEx grid tartalmát.
    // és a formExercise tartalmát
    async function handleCellClick(event) {
        props.setCurrDataRow(event.node.data);
        setRowIndex(event.node.rowIndex);
    }

    const cellClickedListener = useCallback(
        async event => await handleCellClick(event),
        [props.setCurrDataRow]
        // [setRowIndex, rowIndex]
    );

    const onRowEditingStarted = (params) => {
        if (props.formState === 'HBASE') {
            props.setFormState('HEDIT')
        }
        setEditingRowId(params.data.ID); // 🔥 Állapot frissítése

        setTimeout(() => {
            if (gridRef.current && gridRef.current.api) {
                gridRef.current.api.refreshCells({
                    rowNodes: [params.node], // 🔥 Pontosan ezt a sort frissítsük!
                    columns: ["btsave"]
                });
            } else {
                console.warn("⚠️ Grid API nincs elérhető!");
            }
        }, 50);
    };

    const handleStopEditing = () => {
        if (gridRef.current) {
            const api = gridRef.current.api;
            api.stopEditing(true); // Elveti a módosításokat
            // vagy: api.stopEditing(false); // Megtartja a módosításokat
        }
    };

    async function stateChangeHandling() {
        switch (props.formState) {
            case 'HNEW':
                addItem();
                break;
            case 'CANCELNEW':
                handleStopEditing();
                ShowMyData();
                props.setFormState("HBASE")
                break;
            case 'HDEL2':
                await DeleteMyData();
                await ShowMyData();
                props.setFormState("HBASE")
                break;
            default:
        }
    }

    const onRowEditingStopped = (params) => {
        setEditingRowId(0); // 🔥 Állapot frissítése

        setTimeout(() => {
            if (gridRef.current && gridRef.current.api) {
                gridRef.current.api.refreshCells({
                    rowNodes: [params.node], // 🔥 Pontosan ezt a sort frissítsük!
                    columns: ["btsave"]
                });
            } else {
                console.warn("⚠️ Grid API nincs elérhető!");
            }
        }, 50);
    };

    const gridContext = useMemo(() => ({ editingRowId }), [editingRowId]);

    function fieldValid(saveprops) {
        let result = true;
        const validate = myAjv.compile(ajvSchemaExercise);
        result = validate(saveprops)
        return result;
    }

    async function SaveMyData(saveprops) {
        await SaveData({
            token: props.token,
            setRowData: props.setExerciseRowData,
            language: 'HU',
            userName: props.userName,
            collection: myCollection,
            saveprops: saveprops
        })
            .then(async (result) => {
                await ShowMyData();
                props.setFormState("HBASE")
                return;
            })
            .catch((err) => {
                alert(err.response.data.message);
                console.error(err);
            });
    }

    async function DeleteMyData() {
        await DelRow2({
            gridRef: gridRef,
            token: props.token,
            collection: myCollection,
            userName: props.userName,
        }).then(async (result) => {
            await ShowMyData();
            return;
        }).catch((err) => {
            console.error(err);
        })
    }

    function addItem() {
        const newItems = [
            createNewRowDataExercise()
        ];
        const res = gridRef.current.api.applyTransaction({
            add: newItems,
            addIndex: 0,
        });
        return res;
    };

    const onRowValueChanged = useCallback(async (event) => {
        const valid = fieldValid(event.data)
        if (valid) {
            await SaveMyData(event.data)
        }
    }, []);

    return (
        <div className="gridpage">
            <div className="ag-theme-alpine-dark" style={{ width: '100%', height: '300pt' }}>
                <AgGridReact
                    context={gridContext}  // 🔥 Memozott context
                    ref={gridRef}
                    rowData={props.exerciseRowData}
                    columnDefs={columnDefs}
                    // getRowId={getRowId}
                    defaultColDef={defaultColDef}
                    editType="fullRow"
                    rowSelection="multiple"
                    onCellClicked={cellClickedListener}
                    onRowEditingStarted={onRowEditingStarted}
                    onRowEditingStopped={onRowEditingStopped}
                    onRowValueChanged={onRowValueChanged}
                />
            </div>
        </div>
    );
}

export default GridExercise;
