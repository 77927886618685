import { useState, Fragment } from 'react';
import './griffnavbar.scss';
import { NavLink } from 'react-router-dom';
import { List as HamMenuIcon } from 'react-bootstrap-icons';
import { Row, NavDropdown } from 'react-bootstrap';

function GriffNavbar(props) {
    const [hamMenu, setHamMenu] = useState('CLOSED')
    const [submenuOpen, setSubmenuOpen] = useState({}); // Objektum az almenük állapotának követésére

    function closeHamMenu() {
        setHamMenu('CLOSED');
    }

    function changeHamMenu() {
        setHamMenu((currentState) => ((currentState === 'CLOSED') ? 'OPEN' : 'CLOSED'))
    }

    function toggleSubMenu(menuKey) {
        setSubmenuOpen(prevState => ({
            ...prevState,
            [menuKey]: !prevState[menuKey]
        }));
    }
    return (
        <div className='main-navbar'>
            <Row className={`nav1 ${props.loginstate}`}>
                <nav className="main-navbar">
                    <Fragment>
                        <div id="main-navbar-ham" onClick={changeHamMenu}>
                            <HamMenuIcon />
                        </div>
                        <div id="main-menu" className={hamMenu}>
                            <ul className="top-level">
                                <li>
                                    <NavLink to="/home" onClick={closeHamMenu}>Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/esemenyek" onClick={closeHamMenu}>Események</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contacts" onClick={closeHamMenu}>Kapcsolat</NavLink>
                                </li>
                            </ul>
                        </div>
                    </Fragment>
                </nav >
            </Row>
            <Row className={`nav2 ${props.loginstate}`}>
                <nav className="main-navbar">
                    <Fragment>
                        <div id="main-navbar-ham" onClick={changeHamMenu}>
                            <HamMenuIcon />
                        </div>
                        <div id="main-menu" className={hamMenu}>
                            <ul className="top-level">
                                <li>
                                    <NavLink to="/home" onClick={closeHamMenu}>Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/esemenyek" onClick={closeHamMenu}>Események</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contacts" onClick={closeHamMenu}>Kapcsolat</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/organisations" onClick={closeHamMenu}>Egyesületek</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/persons" onClick={closeHamMenu}>Személyek</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/abilities" onClick={closeHamMenu}>Képességek</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/exercise" onClick={closeHamMenu}>Gyakorlatok</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/tgroups" onClick={closeHamMenu}>Csoportok</NavLink>
                                </li>
                            </ul>
                        </div>
                    </Fragment>
                </nav >
            </Row>
        </div>
    )
}

export default GriffNavbar;