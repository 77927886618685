import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './pageHead.scss'

function HandleSubmit() {
}

function PageHead(props) {
    function addItem() {
        props.setOperationState("NEW")
    }

    function cancelOperation() {
        props.setOperationState("CANCEL")
    }

    function cancelNew() {
        props.setOperationState("CANCELNEW")
    }

    function setDelState() {
        props.setOperationState("DEL")
    }

    function delRow() {
        props.setOperationState("DELAGAIN")
    }

    return (
        <Form onSubmit={HandleSubmit()}>
            <div className={`HR1_${props.operationState}`}>
                <Row>
                    <Col md={2} className="pageheadbtnrow">
                        <Button id="gridbtnnew"
                            variant="outline-primary"
                            size="lg"
                            onClick={() => addItem(undefined)}
                        >Új adat</Button>
                        <Button
                            id="gridbtndel"
                            variant="outline-danger"
                            size="lg"
                            onClick={() => setDelState(undefined)}
                        >Törlés</Button>
                    </Col>
                    <Col md={10}>
                    </Col>
                </Row>
            </div>
            <div className={`HR2_${props.operationState}`}>
                <Row>
                    <Col md={2} className="pageheadbtnrow">
                        <Button
                            id="gridbtncancel"
                            variant="outline-secondary"
                            size="lg"
                            onClick={() => cancelNew(undefined)}
                        >Mégsem</Button>
                    </Col>
                    <Col md={10}>
                    </Col>
                </Row>
            </div>
            <div className={`HR3_${props.operationState}`}>
                <Row>
                    <Col md={2} className="pageheadbtnrow">
                        <Button
                            id="gridbtncancel"
                            variant="outline-secondary"
                            size="lg"
                            onClick={() => cancelOperation(undefined)}
                        >Mégsem</Button>
                        <Button
                            id="gridbtndel2"
                            variant="outline-danger"
                            size="lg"
                            onClick={() => delRow(undefined)}
                        >Törlés</Button>
                    </Col>

                    <Col md={10}>
                    </Col>
                </Row>
            </div>
        </Form>
    )
}

export default PageHead;