import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './gridForm.scss'

function HandleSubmit() {

}

function GridForm(props) {

    function addItem() {
        if (props.gridId === '') {
            props.setFormState(`HNEW`)
        }
        else
        {
            props.setFormState(`${props.gridId}NEW`)
        }
    }

    function cancelOperation() {
        if (props.gridId === '') {
            props.setFormState(`HBASE`)
        }
        else
        {
            props.setFormState(`${props.gridId}`)
        }
    }

    function cancelNew() {
        if (props.gridId === '') {
            props.setFormState(`CANCELNEW`)
        }
        else
        {
            props.setFormState(`${props.gridId}CANCELNEW`)
        }
    }

    function setDelState() {
        if (props.gridId === '') {
            props.setFormState(`HDEL`)
        }
        else
        {
            props.setFormState(`${props.gridId}DEL`)
        }
    }

    function delRow() {
        if (props.gridId === '') {
            props.setFormState(`HDEL2`)
        }
        else
        {
            props.setFormState(`${props.gridId}DEL2`)
        }
    }

    return (
        <Form onSubmit={HandleSubmit()}>
            <Row className={`btnrow1 ${props.formState}`}>
                <Col md={2} className="gridformbtnrow">
                    <Button id="gridbtnnew"
                        variant="outline-primary"
                        size="lg"
                        onClick={() => addItem(undefined)}
                    >Új adat</Button>
                    <Button
                        id="gridbtndel"
                        variant="outline-danger"
                        size="lg"
                        onClick={() => setDelState(undefined)}
                    >Törlés</Button>
                </Col>
                <Col md={10}>
                </Col>
            </Row>
            <Row className={`btnrow2 ${props.formState}`}>
                <Col md={2} className="gridformbtnrow">
                    <Button
                        id="gridbtncancel"
                        variant="outline-secondary"
                        size="lg"
                        onClick={() => cancelNew(undefined)}
                    >Mégsem</Button>
                </Col>
                <Col md={10}>
                </Col>
            </Row>
            <Row className={`btnrow3 ${props.formState}`}>
                <Col md={2} className="gridformbtnrow">
                    <Button
                        id="gridbtncancel"
                        variant="outline-secondary"
                        size="lg"
                        onClick={() => cancelOperation(undefined)}
                    >Mégsem</Button>
                    <Button
                        id="gridbtndel2"
                        variant="outline-danger"
                        size="lg"
                        onClick={() => delRow(undefined)}
                    >Törlés</Button>
                </Col>

                <Col md={10}>
                </Col>
            </Row>
        </Form>
    )
}

export default GridForm;