/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import PageHead from "../../components/pagehead/pageHead";
import openRenderer from "../../components/renderers/openRenderer";
import saveRenderer2 from "../../components/renderers/saveRenderer2";
import { validators } from "../../components/validators/validators";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ShowData from "../../components/datahandling/showdata";
import SaveData from "../../components/datahandling/savedata";
import Ajv from 'ajv';
import { ajvSchemaGrid1, createNewRowDataGrid1 } from "./gridDef";
import DelRow2 from "../../components/datahandling/deletedata";
const myAjv = new Ajv()

function Grid1(props) {
    const gridId = 'GRID1'
    const gridRef = useRef();
    const myCollection = 'vTrainingGroups';
    const [editingRowId, setEditingRowId] = useState(null); // A grid sorban szerkesztéskor a save gomb megjelenítéséhez kell.
    const [rowIndex, setRowIndex] = useState();

    useEffect(() => {
        stateChangeHandling();
    }, [props.operationState])

    // 🔹 Adatok betöltése
    useEffect(() => {
        ShowMyData();
    }, []);

    useEffect(() => {
        stateChangeHandling();
    }, [props.pageState])

    const gridContext = useMemo(() => ({ editingRowId }), [editingRowId]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
    }), []);

    const columnDefs = useMemo(() => [
        {
            field: 'ID',
            hide: true
        },
        {
            field: 'TrainingGroupName',
            headerName: 'Csoport',
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: false,
            cellStyle: params => {
                const invalid = validators.validate('required', params.value, props.language)
                if (invalid) {
                    return { backgroundColor: '#6e100a' };
                }
                return null;
            },
            filter: true,
            editable: true
        },
        {
            field: 'Remark',
            headerName: 'Megjegyzés',
            cellEditor: 'agTextCellEditor',
            cellEditorPopup: false,
            filter: true,
            editable: true
        },
        {
            field: 'btnopengrid',
            width: 70,
            headerName: '⤵️',
            cellRenderer: openRenderer,
            cellRendererParams: { form: 'GRID2', setPageState: props.setPageState },
        },
        {
            field: 'btsave',
            width: 70,
            headerName: '💾',
            cellRenderer: saveRenderer2,
            valueGetter: () => "", // 🔄 Üres érték, de a renderer meghívódik
        },
    ], [editingRowId]);

    async function stateChangeHandling() {
        switch (props.operationState) {
            case 'NEW':
                addItem();
                break;
            case 'CANCEL':
                props.setOperationState("NONE")
                break;
            case 'CANCELNEW':
                handleStopEditing();
                ShowMyData();
                props.setOperationState("NONE")
                break;
            case 'DELAGAIN':
                await DeleteMyData();
                await ShowMyData();
                props.setOperationState("NONE")
                break;
            default:
        }
    }

    async function ShowMyData() {
        await ShowData({
            token: props.token,
            setRowData: props.setGrid1RowData,
            language: 'HU',
            userName: props.userName,
            collection: myCollection,
            where: '',
        });
    }

    function addItem() {
        const newItems = [
            createNewRowDataGrid1()
        ];
        const res = gridRef.current.api.applyTransaction({
            add: newItems,
            addIndex: 0,
        });
        return res;
    };

    async function SaveMyData(saveprops) {
        await SaveData({
            token: props.token,
            setRowData: props.setGrid1RowData,
            language: 'HU',
            userName: props.userName,
            collection: myCollection,
            saveprops: saveprops
        })
            .then(async (result) => {
                await ShowMyData();
                props.setOperationState("NONE")
                return;
            })
            .catch((err) => {
                alert(err.response.data.message);
                console.error(err);
            });
    }

    async function DeleteMyData() {
        await DelRow2({
            gridRef: gridRef,
            token: props.token,
            collection: myCollection,
            userName: props.userName,
        }).then(async (result) => {
            await ShowMyData();
            return;
        }).catch((err) => {
            console.error(err);
        })
    }

    function dataRecordValid(saveprops) {
        let result = true;
        const validate = myAjv.compile(ajvSchemaGrid1);
        result = validate(saveprops)
        return result;
    }

    // Ha egy adat sorra kattintok, akkor frissíti a grid2 tartalmát.
    // és a form1 tartalmát
    async function handleCellClick(event) {
        props.setCurrDataRow(event.node.data);
        setRowIndex(event.node.rowIndex);
    }

    const handleStopEditing = () => {
        if (gridRef.current) {
            const api = gridRef.current.api;
            api.stopEditing(true); // Elveti a módosításokat
            // vagy: api.stopEditing(false); // Megtartja a módosításokat
        }
    };

    const cellClickedListener = useCallback(
        async event => await handleCellClick(event),
        [props.setCurrDataRow]
    );

    const onRowValueChanged = useCallback(async (event) => {
        const valid = dataRecordValid(event.data)
        if (valid) {
            await SaveMyData(event.data)
        }
        else {
            alert("NO VALID!")
        }
    }, []);

    const onRowEditingStarted = (params) => {
        if (props.pageState === 'NONE') {
            props.setPageState('EDIT')
        }
        setEditingRowId(params.data.ID); // 🔥 Állapot frissítése

        setTimeout(() => {
            if (gridRef.current && gridRef.current.api) {
                gridRef.current.api.refreshCells({
                    rowNodes: [params.node], // 🔥 Pontosan ezt a sort frissítsük!
                    columns: ["btsave"]
                });
            } else {
                console.warn("⚠️ Grid API nem elérhető!");
            }
        }, 50);
    };

    const onRowEditingStopped = (params) => {
        setEditingRowId(0); // 🔥 Állapot frissítése

        setTimeout(() => {
            if (gridRef.current && gridRef.current.api) {
                gridRef.current.api.refreshCells({
                    rowNodes: [params.node], // 🔥 Pontosan ezt a sort frissítsük!
                    columns: ["btsave"]
                });
            } else {
                console.warn("⚠️ Grid API nincs elérhető!");
            }
        }, 50);
    };

    return (
        <div className={`${gridId}_${props.pageState}`}>
            <PageHead
                setPageState={props.setPageState}
                pageState={props.pageState}
                setOperationState={props.setOperationState}
                operationState={props.operationState}
                gridId={gridId}
            >
            </PageHead>
            <h3>SZAKMAI CSOPORTOK</h3>
            <div className="gridpage">
                <div className="ag-theme-alpine-dark" style={{ width: '100%', height: '300pt' }}>
                    <AgGridReact
                        context={gridContext}  // 🔥 Memozott context
                        ref={gridRef}
                        rowData={props.grid1RowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        editType="fullRow"
                        rowSelection="multiple"
                        onCellClicked={cellClickedListener}
                        onRowEditingStarted={onRowEditingStarted}
                        onRowEditingStopped={onRowEditingStopped}
                        onRowValueChanged={onRowValueChanged}
                    />
                </div>
            </div>
        </div>
    )
}

export default Grid1;