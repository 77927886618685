import React from 'react';
import subformicon from "../icons/subform.svg"

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const buttonClicked = () => {
        props.setPageState(props.form);
    };

    return (
        <span>
            <span>{cellValue}</span>&nbsp;
            <img
                className='subformIcon'
                src={subformicon}
                onClick={() => buttonClicked()}
                alt="pencil"
            />

        </span>
    );
};