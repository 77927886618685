import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { Button, Row } from 'react-bootstrap';
import SaveData from '../../components/datahandling/savedata';
export default function Grid3(props) {
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const myCollection = 'vExToGroupAdd'
    const gridId = 'GRID3'
    const [editingRowId, setEditingRowId] = useState(null); // A grid sorban szerkesztéskor a save gomb megjelenítéséhez kell.

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
    }));

    const getRowId = useCallback(function (params) {
        return params.data.ID;
    }, []);

    const columnDefs = useMemo(() => [
        {
            field: 'ID',
            hide: true,
        },
        {
            field: 'Selected',
            headerName: '✔️',
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            editable: true,
            filter: true,
            width: 100,
        },
        {
            field: 'ExerciseName',
            headerName: 'Gyakorlat',
            cellEditor: 'agTextCellEditor',
            editable: false,
            filter: true,
            width: 280,
        },
        {
            field: 'SportAbilityName',
            headerName: 'Képesség',
            cellEditor: 'agTextCellEditor',
            editable: false,
            filter: true,
            width: 180,
        },
    ], [editingRowId]);

    function CloseMe() {
        props.setPageState(props.parentGridId)
    }

    const handleClose = useCallback(async () => {
        const selectedData = [];
        gridRef.current.api.forEachNode(node => {
            if (node.data.Selected) {
                selectedData.push(node.data.ID);
            }
        });

        if (selectedData.length > 0) {
            try {
                // Példa API hívás a kiválasztott elemekkel
                await SaveData()
                // await axios.post('/api/saveSelectedExercises', {
                //     selectedIDs: selectedData,
                //     trainingGroupID: props.currDataRow?.ID // Példa szülő azonosítóval
                // }, {
                //     headers: { Authorization: props.token }
                // });

                // Szükség esetén frissítsd a szülő grid adatait
                if (props.biRef.SetParent_grid2) {
                    props.biRef.SetParent_grid2(true);
                }
            } catch (err) {
                console.error('Hiba a mentés során:', err);
                alert('Hiba történt a mentés során!');
            }
        }

        props.setPageState(props.parentGridId);
    }, [props.token, props.currDataRow, props.parentGridId]);

    return (
        <div className={`${gridId}_${props.pageState}`}>
            <h3>GRID 3 ADD EXERCISES</h3>
            <div className="gridsection">
                <div className="ag-theme-alpine-dark" style={{ width: '100%', height: '300pt' }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={props.grid3RowData}
                        columnDefs={columnDefs}
                        getRowId={getRowId}
                        defaultColDef={defaultColDef}
                        editType={'fullRow'}
                        rowSelection='multiple'>
                    </AgGridReact>
                </div>
            </div>
            <Row>
                <Button
                    onClick={CloseMe}
                >
                    BEZÁR
                </Button>
            </Row>
        </div>
    )

}
