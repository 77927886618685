import { Col, Row } from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import Pict1 from './pictures/varpalota/varpalota01.jpg'
import Pict2 from './pictures/varpalota/varpalota02.jpg'
import Pict3 from './pictures/varpalota/varpalota03.jpg'
import Pict4 from './pictures/varpalota/varpalota04.jpg'

function Event2025_03_30() {
    return (
        <div className="MsoNormal">
            <Row className="headline">
                <Col md={6}>
                    <h2>Diákolimpia területi elődöntő, Várpalota</h2>
                </Col>
                <Col md={6}>
                    <h2>2025.03.30</h2>
                </Col>
            </Row>
            <br></br>
            <Row md={4}>
                <Col md={4}>
                    <p>
                        <h4>
                            <p>
                                Két arany, és három bronz érem a Diákolimpia elődöntőn.
                            </p>
                        </h4>
                    </p>
                    <Image src={Pict1} fluid />
                    <p>Balogh Bertalan 1. hely</p>
                    <br></br>
                    <br></br>
                    <Image src={Pict2} fluid />
                    <p>Szalai Kitti 3. hely</p>
                    <br></br>
                </Col>
                {/* <Col md={1}>
                </Col> */}
                <Col md={4}>
                    <h5>
                        <br></br>
                        <p>
                            A kyokushin karate utánpótlás versenyek legrangosabb eseménye, a minden év tavaszán megrendezett Kyokushin Karate Diákolimpia. A Magyar Diáksport Szövetség hirdeti meg, de a Magyar Karate Szakszövetség megbízásából a Kyokushin Karate Szervezetek bonyolítják le. A verseny mindig több fordulóból áll, területi elődöntőkből, és döntőből.
                        </p>
                        <br></br>
                        <p>
                            A Griff Sportegyesület karatésai a dunántúli elődöntőn indultak Várpalotán, március 30-án, hogy innen jó szereplés esetén tovább jussanak a május 18-án, Szigetszentmiklóson megrendezendő országos döntőbe.
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Wölfinger Liza</u></b> kata és küzdelem kategóriában is nevezett, de ezen a versenyen dobogós helyezést sajnos nem ért el. Kata-ban 16-os mezőny alakult ki, és mivel az első körben kiesett, nem valószínű, hogy forma gyakorlatban az országos döntőn indulhat.
                        </p>
                        <p>
                            Küzdelemben technikai okok miatt nem tudott tatamira lépni, de a kis létszám miatt bízunk benne, hogy az országos döntőn is részt vehet. Ott tudja majd bizonyítani, hogy komoly felkészülésen van túl.
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Szalai Kitti</u></b> serdülő küzdelemben indult, ahol 7-es mezőny alakult ki. Már az első küzdelemben búcsúzott a versenytől. Hiába látszott rajta az elmúlt időszak edzésein felszedett erő, a küzdelem közben nyújtott aktivitás alapján a bírók ellenfelét látták jobbnak. A dobogóra így is felállhatott, az országos döntőben bizonyíthatja felkészültségét.🥉
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Mógor Dorka</u></b> serdülő kata mezőnyben indult, ahol nagy volt a tolongás. Tizenhét versenyző jött össze a kategóriában. Két győztes produkcióval a legjobb négy közé jutott, de döntőbe már nem sikerült bejutnia. Bár az előadott forma gyakorlat nagyon szép volt, a bírók végül ellenfelét látták jobbnak. Dorka a népes mezőnyben az előkelő 3. helyet szerezte meg. Gratulálunk!!!🥉
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Molnár Menő</u></b> idén első éves junior, ráadásul a legfelső súly kategóriában. Hét fős mezőny alakult ki, és első körben egy tapasztalt versenyzővel került össze, akit nem sikerült legyőznie, és aki végül a döntőig menetelt. Ellenfele az első percben comb rúgással szerzett fél pontot. Benő később egy fej rúgással majdnem egyenlített, két bíró megadta a wazaarit, de végül nem született pozitív ítélet. A maradék idő már kevés volt az egyenlítésre. Benőnek így sincs oka szégyenkezni. Láthatóan nagyon erős, és ebben a küzdelemben a rutin hiánya miatt maradt alul. Nem volt könnyű ellenfél, és az országos döntőn is biztosan megállja majd a helyét. A megszerzett tapasztalatok alapján készülünk tovább.
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Gáncs Arnold</u></b> serdülő kata és küzdelem kategóriában is indult. A forma gyakorlatban itt is népes mezőny alakult ki, nagy volt a rivalizálás. 13-an neveztek, és Arni a 16-os körből indult. Két győztes küzdelemmel jutott a legjobb 4 közé, ahol a címvédőtől kapott ki. Szép teljesítménye a dobogó 3. helyére juttatta.🥉
                        </p>
                        <p>
                            Küzdelemben a serdülő fiú könnyűsúlyban 4-en indultak. Első körben azt a Lukács Filipet verte, akitől korábban már több alkalommal kikapott. Most azonban beérett a sok felkészülés, és érvényesült a tehetség, hosszabbítás nélkül, az első menetben egyértelmű győzelmet aratott, egy hajtós küzdelemben.
                            Arnira még két küzdelem várt, de legyőzni ezen a napon őt senki nem tudta. Hasonlóan szép, rutinos és elszánt küzdelemmel a dobogó legfelső fokára verekedte magát. Szép volt Arni!!!🥇
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Balogh Bertalan</u></b> szintén komoly felkészülés után, a junior férfi könnyű súlyban 4-es mezőnyben találta magát. Első küzdelmét a VTSE versenyzőjével vívta, aki az első fél percben jól állta Berci erős támadásait. Láthatóan rutinos versenyzővel volt dolgunk, aki nem adta könnyen magát. Az első perc vége felé azonban már egyértelműen látszott, hogy ellenállása gyengül, és a küzdő teret a Griff SE harcosa uralja. Bár wazaari nem született, Bertalan az első menet végén egyértelmű bírói döntéssel győztesként hagyta el a pástot.
                        </p>
                        <p>
                            Második küzdelme nem tartott 2 percig, mert két wazaarival a küzdő idő előtt fél perccel győzött.                            Harmadik ellenfele sajnos sérülés miatt feladta a küzdelmet, és nem állt ki Berci ellen.
                        </p>
                        <p>
                            Harmadik ellenfele sajnos sérülés miatt feladta a küzdelmet, és nem állt ki Berci ellen.
                        </p>
                        <p>
                            Balogh Bertalan nagyon szép, technikás és erős küzdelemmel megérdemelten állhatott a junior könnyű súly kategóriában a dobogó legfelső fokára. Gratulálunk!!! Így tovább az országos döntőben is!🥇
                        </p>
                        <br></br>
                        <br></br>
                        <br></br>
                        <p>
                            A Griff Sportegyesület mérlege: Két arany és két bronz érem.
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            Gratulálunk a versenyzőknek, és nagy köszönet egyesületünk edzői csapatának, akik külön edzéseken foglalkoztak indulóinkkal,
                            <p>
                                <h4>
                                    <li>
                                        Sinkó Attila<br></br>
                                    </li>
                                    <li>
                                        Mógor Tibor<br></br>
                                    </li>
                                    <li>
                                        Balogh László<br></br>
                                    </li>
                                </h4>
                            </p>


                            Készülünk tovább a májusi országos döntőre.
                        </p>
                        {/* <Image src={Pict4} fluid /> */}
                    </h5>
                </Col>
                <Col md={4}>
                    <Image src={Pict3} fluid />
                    <p>Gáncs Arnold 1.hely</p>
                    <br></br>
                    <br></br>
                    <Image src={Pict4} fluid />
                    <p>Mógor Dorka 3.hely</p>
                </Col>
            </Row>
        </div>
    )

}

export default Event2025_03_30;