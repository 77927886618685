import { Col, Row } from "react-bootstrap"
import Image from 'react-bootstrap/Image';
import Pict1 from './pictures/kapuvar/kapuvar01.jpg'
import Pict2 from './pictures/kapuvar/kapuvar02.jpg'
import Pict3 from './pictures/kapuvar/kapuvar03.jpg'
import Pict4 from './pictures/kapuvar/kapuvar04.jpg'
import Pict5 from './pictures/kapuvar/kapuvar05.jpg'

function Event2025_03_22() {
    return (
        <div className="MsoNormal">
            <Row className="headline">
                <Col md={6}>
                    <h2>Castrum Kupa, Kapuvár</h2>
                </Col>
                <Col md={6}>
                    <h2>2025.03.22</h2>
                </Col>
            </Row>
            <br></br>
            <Row md={4}>
                <Col md={4}>
                    <p>
                        <h5>
                            <p>
                                Eljött a 2025-ös év első versenyének napja: március 22., Kapuvár.
                            </p>
                        </h5>
                    </p>
                    <Image src={Pict5} fluid />
                    <br></br>
                    <br></br>
                    <Image src={Pict2} fluid />
                    <br></br>
                </Col>
                {/* <Col md={1}>
                </Col> */}
                <Col md={4}>
                    <h5>
                        <br></br>
                        <p>
                            A Griff SE. csapata első alkalommal vett részt a Castrum SC által rendezett gyakorló versenyen. Jó lehetőség azok számára, akik országos versenyhez még nem elég képzettek, de szeretnék magukat kipróbálni verseny szituációban is.
                            A rendezvényen lehetőség volt úgynevezett „csipesz harcban”, és kyokushin küzdelemben is indulni. Egyesületünk 10 főt delegált erre a versenyre.
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            A Griff SE-t csipesz harcban egy versenyző, <b><u>Pék Marci</u></b> képviselte, aki három küzdelemből két
                            küzdelmet meg is nyert, így került a dobogó harmadik fokára. 🥉
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Gáncs Bogi</u></b> csipesz harcban korábban már több versenyen is szerepelt sikeresen,
                            de Kapuváron most első alkalommal, küzdelemben indult.
                            Jóval nagyobb ellenfelei ellen győztes küzdelme ugyan nem volt, de bátor harccal több pontot is szerzett.
                            Végül a 3. helyen zárta a versenyt.🥉
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Portik Marián</u></b>, aki korábban - úgy, mint Bogi - többször is csipesz harcban indult,
                            ezen a versenyen szintén küzdelemben próbálta ki magát. A „műfaj váltás” végül indokoltnak bizonyult,
                            mert két győztes küzdelem után a dobogó legfelső fokára állhatott.🥇
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Piller Ábris</u></b> felkészültsége már edzésen is sokszor megmutatkozott. Második alkalommal indult
                            küzdelemben, és villámgyors technikáival Kapuváron is pillanatok alatt gyűrte le ellenfeleit.
                            Három győzelme arany érmet ért Kapuváron is, mint korábban Budapesten.🥇
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Halász Zente</u></b> fegyelmezett munkával készült edzésről-edzésre. Két ellenfele közül az első közel egy fejjel magasabb volt nála, és szoros küzdelem után az első menet 4-4-es döntetlent hozott. A hosszabbításban szerzett két pont végül Zente győzelmét jelentette. Második küzdelme már könnyebbnek bizonyult, ezt magabiztosan nyerte, így ő is 1. helyen végzett.🥇
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Portik Miron</u></b> már több versenyen is indult, Kapuváron talán már a negyedik alkalommal lépett tatamira. Két ellenfelét pontos találatokkal utasította maga mögé, és végül arany éremmel térhetett haza.🥇
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Pődör Panka</u></b>  szintén több versenyen indult már. Edzéseken tanúsított szorgalma, a versenyzés terén tanúsított motiváltsága ezen a versenyen, három győzelemmel őt is a dobogó legtetejére repítette. Taktikás, pontos küzdelmeit öröm volt nézni.🥇
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Pődör József</u></b> alkalommal indult versenyen, de EKO szisztémában most először. Ez azt jelenti, hogy a pontozás helyett folyamatosan ment a küzdelem, ezért nem csak ügyességre, gyorsaságra, hanem állóképességre is szükség volt. Két küzdelméből az elsőt sikerült megnyernie, ami a második helyezésre volt elegendő.🥈
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Bakos Gergő</u></b> szintén 4-es mezőnyben indult, így három küzdelem várt rá. Ez alatt a 3 küzdelem alatt bizonyította, hogy szorult helyzetekben sem adja fel a harcot. Kitartása végül két győzelmet, és a második helyet jelentette számára.🥈
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            <b><u>Balázs Dénes</u></b> 4 fős mezőnyben indult. Ellenfelei között voltak tapasztaltabb versenyzők is, akikkel nagy harcokat vívott. A végül két győzelemmel megszerzett második hely értékes tapasztalatokat jelentett számára a további versenyzés terén.🥈
                        </p>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <p>
                            A Griff SE mérlege: 5 arany, 3 ezüst és 3 bronz.
                            Köszönet a felkészítőknek, és a versenyen közreműködő edzőknek, Mógor Tibornak és Szalóki Ildikónak, valamint köszönjük a szülők bizalmát, támogatását.
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                            Gratulálunk minden versenyzőnek!
                        </p>
                        <Image src={Pict4} fluid />
                    </h5>
                </Col>
                <Col md={4}>
                    <Image src={Pict1} fluid />
                    <br></br>
                    <br></br>
                    <Image src={Pict3} fluid />
                </Col>
            </Row>
        </div>
    )

}

export default Event2025_03_22;