export const ajvSchemaGrid1 = {
    type: "object",
    properties: {
        ID: { type: "integer" },
        TrainingGroupName: { type: "string", minLength: 1 },
        Remark: {},
    },
    required: [
        "TrainingGroupName",
    ],
    additionalProperties: false,
}

export const ajvSchemaGrid2 = {
    type: "object",
    properties: {
        ID: { type: "integer" },
        ExerciseID: { type: "integer" },
        UnitTypesID: {},
        ResultTypeDescription: { type: "string", minLength: 1 },
        UnitName: { type: "string", minLength: 1 },
        ExerciseName: {},
    },
    required: [
        "UnitName",
        "ResultTypeDescription",
        "ExerciseID"
    ],
    additionalProperties: false,
}

export function createNewRowDataGrid1() {
    const newData = {
        ID: 0,
        TrainingGroupName: "",
        Remark: "",
    };
    return newData;
}

export function createNewRowDataGrid2() {
    const newData = {
        ID: 0,
        ResultTypeDescription: "",
        UnitName: "---",
    };
    return newData;
}
