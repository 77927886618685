import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import saveRenderer2 from '../../components/renderers/saveRenderer2';
import { validators } from '../../components/validators/validators';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import ShowData from '../../components/datahandling/showdata';
import SaveData from '../../components/datahandling/savedata';
import Ajv from 'ajv';
import PageHead from "../../components/pagehead/pageHead";
import { Button, Row } from 'react-bootstrap';
import { ajvSchemaGrid2, createNewRowDataGrid2 } from './gridDef';
import DelRow2 from '../../components/datahandling/deletedata';
const myAjv = new Ajv();

function Grid2(props) {
    const gridRef = useRef(); // Optional - for accessing Grid's API
    const myCollection = 'vExToGroup'
    const gridId = 'GRID2'
    const [editingRowId, setEditingRowId] = useState(null); // A grid sorban szerkesztéskor a save gomb megjelenítéséhez kell.
    const parentIDRef = useRef(null);
    const parentNameRef = useRef(null);

    useEffect(() => {
        if (props.currDataRow) {
            parentIDRef.current = props.currDataRow.ID;
            parentNameRef.current = props.currDataRow.TrainingGroupName;
        }
    }, [props.currDataRow]);

    useEffect(() => {
        stateChangeHandling();
    }, [props.formState, props.currDataRow])


    props.biRef.SetParent_grid2 = setParentData;

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
    }));

    const columnDefs = useMemo(() => [
        {
            field: 'ID',
            hide: true,
        },
        {
            field: 'ExerciseName',
            headerName: 'Gyakorlat',
            cellEditor: 'agTextCellEditor',
            editable: false,
            width: 180,
        },
        {
            field: 'btsave',
            width: 50,
            headerName: '💾',
            cellRenderer: saveRenderer2,
            valueGetter: () => "", // 🔄 Üres érték, de a renderer meghívódik
        },
    ], [editingRowId]);

    const getRowId = useCallback(function (params) {
        return params.data.ID;
    }, []);

    function CloseMe() {
        props.setPageState(props.parentGridId)
    }

    async function setParentData(params) {
        if (params) {
            await ShowMyData();
        }
    }
    async function stateChangeHandling() {
        switch (props.formState) {
            case 'GRID2NEW':
                // addItem();
                break;
            case 'GRID2CANCELNEW':
                // handleStopEditing();
                ShowMyData();
                props.setFormState(gridId)
                break;
            case 'GRID2DEL2':
                await DeleteMyData();
                await ShowMyData();
                // await ShowParentData();
                props.setFormState(gridId)
                break;
            default:
        }
    }

    async function DeleteMyData() {
        return await DelRow2({
            gridRef: gridRef,
            token: props.token,
            collection: myCollection,
            userName: props.userName,
        }).then(async (result) => {
            return result;
        }).catch((err) => {
            console.error(err);
        })
    }

    async function ShowMyData() {
        let mywhere = `TrainingGroupID = ${parentIDRef.current}`;
        await ShowData({
            token: props.token,
            setRowData: props.setGrid2RowData,
            language: 'HU',
            userName: props.userName,
            collection: myCollection,
            where: mywhere,
        });
    }

    return (
        <div className={`${gridId}_${props.pageState}`}>
            <div>
                <PageHead
                    setPageState={props.setPageState}
                    pageState={props.pageState}
                    gridId={gridId}
                    setOperationState={props.setOperationState}
                    operationState={props.operationState}
                >
                </PageHead>
                <h4>{parentNameRef.current} gyakorlatai</h4>
            </div>
            <div className="gridsection">
                <div className="ag-theme-alpine-dark" style={{ width: '100%', height: '300pt' }}>
                    <AgGridReact ref={gridRef}
                        rowData={props.grid2RowData}
                        columnDefs={columnDefs}
                        getRowId={getRowId}
                        defaultColDef={defaultColDef}
                        editType={'fullRow'}
                        rowSelection='multiple'>
                    </AgGridReact>
                </div>
            </div>
            <Row>
                <Button
                    onClick={CloseMe}
                >
                    BEZÁR
                </Button>
            </Row>

        </div>
    )
}

export default Grid2;